<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >

    <b-alert
      class="p-2 mt-2"
      style="height: 100%;position: relative;"
      show
      variant="warning"
    >
      <span> {{ $t('add-mobile-warning') }}</span>
      <br>
      <br>
      <b-button
        class="mx-auto d-block w-25"
        :to="'/'+this.$i18n.locale+'/profile/general'"
      >
        {{ $t('profile') }}
      </b-button>
    </b-alert>

    <b-card
      v-if="withdrawal"
      no-body
    >
      <div>
        <b-row>
          <b-col
            md="4"
            class="card-border"
          >
            <b-card-body>
              <div>
                <h2 class="mb-1">
                  {{ $t('fiatWidraw.description') }}
                </h2>
                <p>{{ $t('fiatWidraw.text') }}</p>
                <div class="d-flex justify-content-center mt-3">
                  <b-button
                    variant="primary"
                    :to="`/${$i18n.locale}/document/bank`"
                  >

                    {{ $t('fiatWidraw.buttontxt') }}
                  </b-button>
                </div>
                <GetDescription name="forex-bank-withdrawal" />
              </div>
            </b-card-body>
          </b-col>
          <b-col md="8">
            <b-card-body>
              <b-alert
                v-if="buyConvertRate || sellConvertRate"
                show
                class="p-1"
              >
                <b-row>
                  <b-col
                    v-if="buyConvertRate"
                    cols="12"
                    sm="6"
                  >
                    <p>
                      <span>{{ $t('convert_rate_buy') }}</span>
                      <span style="margin:0 4px">:</span>
                      <span
                        v-if="numbers.currency"
                        dir="ltr"
                      >{{ helpers.numberWithCommas(buyConvertRate)+' '+numbers.currency.currency }}</span>
                    </p>
                  </b-col>
                  <b-col
                    v-if="sellConvertRate"
                    cols="12"
                    sm="6"
                  >
                    <p>
                      <span>{{ $t('convert_rate_sell') }}</span>
                      <span style="margin:0 4px">:</span>
                      <span
                        v-if="numbers.currency"
                        dir="ltr"
                      >{{ helpers.numberWithCommas(sellConvertRate)+' '+numbers.currency.currency }}</span>
                    </p>
                  </b-col>
                </b-row>
              </b-alert>
              <div class="my-2">
                <metatraderAccountDetail
                  :acc="accountDetail"
                />
              </div>
              <validation-observer ref="simpleRules">
                <b-form class="auth-forgot-password-form mt-2">
                  <b-row>
                    <b-col md="6">
                      <b-form-group
                        :label="$t('forex.account-number')"
                        label-for="account-number"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('forex.account-number')"
                          vid="account-number"
                          rules="required"
                        >
                          <v-select
                            id="account-number"
                            v-model="accSelected"
                            :options="accounts"
                            :clearable="false"
                            label="account"
                            @input="onchangeAccountDetail"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        :label="$t('forex.platform')"
                        label-for="platform"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('forex.platform')"
                          vid="platform"
                          rules="required"
                        >
                          <v-select
                            id="platform"
                            v-model="platformSelected"
                            :options="platform"
                            label="name"
                            disabled
                            :clearable="false"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label-for="currency"
                        :label="$t('fiatWidraw.currency')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          vid="currency"
                          :name="$t('fiatWidraw.currency')"
                          rules="required"
                        >
                          <v-select
                            id="currency"
                            v-model="currency"
                            :options="currencies"
                            :placeholder="$t('fiatWidraw.select')"
                            label="fullname"
                            :clearable="false"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            @input="changeCurrency"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        :label="$t('fiatWidraw.amount')"
                        label-for="amount"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('fiatWidraw.amount')"
                          rules="required|number_or_decimal|decimal_points:2"
                        >
                          <b-form-input
                            v-model="amount"
                            name="amount"
                            :placeholder="`${$t('fiatWidraw.amount')}...`"
                            autocomplete="nope"
                            :formatter="numberFormat"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                  </b-row>
                  <b-row>
                    <b-col
                      sm="12"
                      class="mb-2"
                    >
                      <b-form-group
                        class="right-selector"
                        :label="$t('choose-bank-card')"
                        label-for="numbers"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('fiatDiposit.bank_card')"
                          rules="required"
                        >
                          <v-select
                            id="numbers"
                            v-model="numbers"
                            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                            :options="bankCard"
                            :placeholder="$t('fiatWidraw.select')"
                            :clearable="false"
                            :get-option-label="(option) => option.numbers"
                            @input="changeCurrency"
                          >
                            <template #option="item">
                              <div>
                                <div>
                                  <span>{{ $t('iban') }} : {{ item.ibanCurrency }}</span>
                                  <br>
                                  <span>{{ $t('card_numbers') }} : {{ item.numbers }}</span>
                                </div>
                              </div>
                            </template>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  data-toggle="modal"
                  data-target="#myModal"
                  variant="success"
                  class="btn-block"
                  @click="showModal()"
                >

                  <span v-if="!submitLoading">{{ $t('withdrawal-request') }}</span>
                  <div v-if="submitLoading">
                    <b-spinner
                      small
                      type="grow"
                    />
                    <span>{{ $t('loading') }}</span>
                  </div>
                </b-button>
                <b-modal
                  id="modal-scoped"
                  centered
                  size="lg"
                >
                  <template #modal-header="{ close }">
                    <feather-icon
                      icon="XIcon"
                      size="16"
                      style="cursor:pointer;"
                      color="white"
                      @click="close()"
                    />
                  </template>
                  <template>
                    <b-card>
                      <b-card-body class="card-padding ">
                        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                          <div>
                            <div class="d-flex align-items-center mb-2">

                              <h3 class="text-primary vuexy">
                                {{ $t('invoice.brandName', { brand_name: getBrandName() }) }}
                              </h3>
                            </div>
                            <h6 class="mb-2">
                              {{ $t('invoice.invoiceTo') }}:
                            </h6>
                            <div
                              class="text-left"
                              dir="ltr"
                              style="text-align:left !important"
                            >
                              <p class="card-text  ">
                                {{ $store.state.auth.user.first_name }} {{
                                  $store.state.auth.user.last_name
                                }}
                              </p>
                              <p class="card-text">
                                {{ $store.state.auth.user.email }}
                              </p>
                              <p class="card-text mb-0">
                                +{{ $store.state.auth.user.mobile_country_code }} {{
                                  $store.state.auth.user.mobile
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="mt-md-0">
                            <div class="d-flex align-items-center mt-3">
                              <p class="invoice-date-title">
                                {{ $t('invoice.dateIssued') + ':' }}
                              </p>
                                  &nbsp;
                              <p class="invoice-date">
                                {{ new Date().toUTCString() }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </b-card-body>

                      <hr class="my-2">
                      <b-card-body class="card-padding " />
                      <b-row class="row-bg p-1 bg-success text-white font-weight-bold">
                        <b-col cols="7">
                          <b-row class="d-flex justify-content-around ">
                            <b-col>

                              <div>{{ $t('fiatWidraw.amount') }}</div>
                              {{ helpers.numberWithCommas(amount) + ' ' + currency.currency }}

                            </b-col>
                            <b-col>
                              <div>{{ $t('widrawModal.iban') }}</div>
                              <div class="text-nowrap">
                                {{ numbers.iban }}
                              </div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-card-body>

                        <hr class="my-2">
                        <b-row class="card-border">
                          <b-col
                            md="6"
                            class="d-flex
                                order-md-2
                                order-1"
                          >
                            <div class="invoice-total-wrapper">
                              <div class="invoice-total-item d-flex justify-content-between">
                                <p class="invoice-total-title">
                                  {{ $t('total') }}:
                                </p>
                                    &nbsp;
                                <p class="invoice-total-amount">
                                  {{ helpers.numberWithCommas(amount) + ' ' + currency.currency }}
                                </p>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </b-card-body>
                    </b-card>
                  </template>

                  <template #modal-footer="{ ok }">
                    <b-button
                      size="md"
                      variant="success"
                      @click="withdrawalSendOtp()"
                    >
                      <span v-if="!resendOtpLoading"> {{ $t('widrawModal.confirm') }}</span>
                      <b-spinner
                        v-else
                        small
                        size="10"
                        variant="light"
                        type="grow"
                      />
                    </b-button>
                  </template>
                </b-modal>
              </b-col>
            </b-card-body>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card v-if="!withdrawal">
      <h4
        class="text-justify"
        style="line-height: normal;"
      >
        {{ $t('withdrawal-desc') }}
      </h4>
    </b-card>
    <b-card
      v-if="items.length > 0"
      class="d-none d-lg-block"
    >
      <b-row align-h="center">
        <b-col cols="12">
          <b-table
            :items="items"
            :fields="fields"
            striped
            sticky-header="1000px"
          >
            <template #cell(date)="data">
              <span>{{ getFormattedDate(data.item["date"]) }}</span>
            </template>
            <template #cell(amount)="data">
              <span>{{ helpers.numberWithCommas(data.item.amount) }}</span>
            </template>
            <template #cell(status)="data">
              <div class="d-flex align-items-center">
                <b-button
                  :variant="data.item.detail['status'] === 'pending' ? 'warning' : data.item.detail['status'] === 'successful' ? 'success' :data.item.detail['status']? 'danger':''"
                  size="sm"
                  style="display: block; width: 140px"
                  @click="openInfoTransactionModal(data.item)"
                >
                  {{ $t(data.item.detail.status) }}
                </b-button>
                <feather-icon
                  v-if="data.item.detail['status'] === 'rejected' && data.item.detail['reject_reason']"
                  :id="`popover-button-variant-${data.item.id}`"
                  icon="HelpCircleIcon"
                  class="cursor-pointer mx-1"
                  size="20"
                  href="#"
                  tabindex="0"
                />
                <b-popover
                  v-if="data.item.detail['status'] === 'rejected' && data.item.detail['reject_reason']"
                  placement="top"
                  :target="`popover-button-variant-${data.item.id}`"
                  variant="secondary"
                  triggers="focus"
                >
                  {{ data.item.detail['reject_reason'] ? data.item.detail['reject_reason']:'' }}
                </b-popover>
              </div>
            </template>

          </b-table>
        </b-col>
        <b-col cols="auto">
          <b-pagination
            v-if="totalTransactions > perPageTransactions"
            v-model="currentpageTransactions"
            :total-rows="totalTransactions"
            :per-page="perPageTransactions"
            align="fill"
            size="sm"
            class="mb-1"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="items.length > 0"
      class="d-lg-none "
      :title="$t('lastWithdrawal')"
    >
      <b-row
        align-h="center"
      >
        <b-col cols="12">
          <div
            v-for="(item, index) in items"
            :key="index"
            :class="$store.state.appConfig.layout.skin === 'dark' ? 'card-bg' : 'transaction-card'"
            class="mb-2"
          >
            <div class="d-flex justify-content-between">
              <div>{{ $t('id') }}</div>
              <h5>{{ item.automation_id }}</h5>

            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ $t('forex.account-number') }}</div>
              <h5>{{ item.account }}</h5>

            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ $t('forex.amount') }}</div>
              <h5>{{ helpers.numberWithCommas(item.amount) }}</h5>

            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ $t('date') }}</div>
              <h5>{{ getFormattedDate(item.date) }}</h5>

            </div>

            <div class="d-flex justify-content-between mt-1">
              <div>{{ $t('forex.destination-account') }}</div>
              <h5>{{ item.bank.numbers }}</h5>

            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ $t('forex.currency') }}</div>
              <h5>{{ item.currency.currency }}</h5>

            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ $t('status') }}</div>
              <div class="d-flex align-items-center">
                <feather-icon
                  v-if="item.detail['status'] === 'rejected' && item.detail.reject_reason"
                  :id="`popover-button-variant-${item.id}+mobile`"
                  icon="HelpCircleIcon"
                  class="cursor-pointer"
                  size="20"
                  href="#"
                  tabindex="0"
                />
                <b-popover
                  v-if="item.detail['status'] === 'rejected' && item.detail.reject_reason"
                  placement="top"
                  :target="`popover-button-variant-${item.id}+mobile`"
                  variant="secondary"
                  triggers="focus"
                >
                  {{ item.detail.reject_reason ? item.detail.reject_reason:'' }}
                </b-popover>
                <div style="margin: 0 5px;" />

                <b-button
                  :variant="item.detail['status'] === 'pending' ? 'warning' : item.detail['status'] === 'successful' ? 'success' :item.detail['status']? 'danger':''"
                  size="sm"
                  style="display: block; width: 140px"
                > {{ $t(item.detail.status) }}</b-button>
              </div>
            </div>

          </div>
        </b-col>
        <b-col cols="auto">
          <b-pagination
            v-if="totalTransactions > perPageTransactions"
            v-model="currentpageTransactions"
            :total-rows="totalTransactions"
            :per-page="perPageTransactions"
            align="fill"
            size="sm"
            class="mt-2 mb-1"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-row v-if="alertShow">
      <b-col sm="12">
        <b-alert
          variant="success"
          show
          class="px-1"
        >
          <div class="alert-body p-2">
            <h4>
              {{ $t('successful-widrawModal') }}
            </h4>
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <b-modal
      v-model="infoTransactionModal"
      :title="selectedTransaction ? $t('info_transaction_modal', { id: selectedTransaction[0].id }) : ''"
      hide-footer
      size="lg"
      modal-class="transaction-modal"
    >
      <b-alert
        v-if="selectedTransaction && selectedTransaction[0].detail.reject_reason"
        variant="danger"
        show
      >
        <h5 class="alert-heading">
          {{ $t('rejectDescription') }}:
        </h5>
        <div class="alert-body p-2">
          <p>
            {{ selectedTransaction && selectedTransaction[0].detail.reject_reason }}
          </p>
        </div>
      </b-alert>
      <!-- request info -->
      <h5 class="mt-1">
        {{ $t('request_info') }}
      </h5>
      <b-table
        hover
        responsive
        :items="selectedTransaction"
        :fields="transactionModalRequestFields"
      />
      <hr>
      <!-- request destination -->
      <h5 class="mt-2">
        {{ $t('forex.destination-account') }}
      </h5>
      <b-table
        hover
        responsive
        :items="selectedTransaction"
        :fields="transactionModalAccountFields"
      />
    </b-modal>
    <b-modal
      v-model="otpModal"
      :title="$t('confirm_withdraw')"
      centered
      size="md"
      hide-footer
    >
      <validation-observer
        #default="{invalid}"
      >
        <b-row>
          <b-col>
            <div
              v-if="mobile"
              class="my-1"
            >
              {{ $t('withdraw_confirm_description', { mobile }) }}
            </div>
            <b-form
              class="auth-login-form mb-2"
            >
              <!-- pin -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="code">{{ $t('confirm_withdraw_code') }}</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('confirm_withdraw_code')"
                  vid="code"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      id="code"
                      v-model="confirmCode"
                      name="code"
                      placeholder="0000"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-button
              block
              size="md"
              variant="warning"
              :disabled="verifyCounter !== 0"
              @click="withdrawalSendOtp"
            >
              <div v-if="!resendOtpLoading">
                <span>
                  {{ $t('resend') }}
                </span>
                <span v-if="verifyCounter !== 0">({{ convertSecondsToMinutes(verifyCounter) }})</span>
              </div>
              <b-spinner
                v-else
                small
                size="10"
                variant="light"
                type="grow"
              />
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button
              block
              size="md"
              :disabled="invalid || confirmCode.length < 4 || confirmPending"
              variant="success"
              @click="submit"
            >
              <span v-if="!confirmPending">
                {{ $t('widrawModal.confirm') }}
              </span>
              <b-spinner
                v-else
                small
                size="10"
                variant="light"
                type="grow"
              />
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </b-overlay>
</template>
<script>

import {
  BCard, BCol, BRow, BCardBody, BFormGroup, BFormInput, BButton, BModal, BOverlay, BForm, BAlert, BTable, BPagination, BPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import MetatraderService from '@/modules/forex/service/apis'
import metatraderAccountDetail from '@/modules/forex/views/metatrader/metatraderAccountDetail.vue'
import moment from '@/libs/moment'
import { convertSecondsToMinutes, numberWithCommas } from '@/utils/index'
import BankApis from '@/services/apis/documents/bank'
import FiatApis from '@/modules/crypto/services/apis/fiat'
import { alphanumeric } from '@/mixins/alphanumeric'
import GetDescription from './components/getDescription.vue'

const fiat = new FiatApis()
const bankList = new BankApis()
const metatrader = new MetatraderService()
export default {
  components: {
    GetDescription,
    BCard,
    BCol,
    BRow,
    BCardBody,
    BFormInput,
    BFormGroup,
    BButton,
    BModal,
    vSelect,
    BOverlay,
    BForm,
    BAlert,
    BTable,
    metatraderAccountDetail,
    BPagination,
    BPopover,
  },
  directives: {
    Ripple,
  },
  mixins: [alphanumeric],
  data() {
    return {
      show: false,
      withdrawal: true,
      otpModal: false,
      confirmCode: '',
      resendOtpLoading: false,
      verifyCounter: 0,
      iban: '',
      ibans: [],
      amount: '',
      required,
      convertSecondsToMinutes,
      verfyCounterTimeout: null,
      submitLoading: false,
      alertShow: false,
      balance: '',
      token: '',
      accounts: [],
      accSelected: '',
      platform: [
        { title: 'metatrader 4', value: 'mt4' },
      ],
      currencies: [],
      platformSelected: '',
      currency: '',
      accountDetail: {
        account: {},
        balance: '',
        equity: '',
        group: '',
        leverage: '',
        margin: '',
        platform: '',
      },
      items: [],
      numbers: '',
      bankCard: [],
      helpers: {
        numberWithCommas,
      },
      numberWithCommas,
      currentpageTransactions: 1,
      totalTransactions: 0,
      perPageTransactions: 10,
      sellConvertRate: null,
      buyConvertRate: null,
      infoTransactionModal: false,
      selectedTransaction: null,
    }
  },
  computed: {
    fields() {
      return [
        {
          label: this.$t('id'),
          key: 'automation_id',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('forex.account-number'),
          key: 'account',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('forex.amount'),
          key: 'amount',
          tdClass: 'nameOfTheClass',
        },

        {
          label: this.$t('date'),
          key: 'date',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('forex.destination-account'),
          key: 'bank.numbers',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('currency'),
          key: 'currency.currency',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('status'),
          key: 'status',
          tdClass: 'nameOfTheClass',
        },
      ]
    },
    transactionModalRequestFields() {
      return [
        {
          label: this.$t('account'),
          key: 'account',
        },
        {
          label: this.$t('amount'),
          key: 'amount',
        },
        {
          label: this.$t('currency'),
          key: 'currency',
        },
      ]
    },
    transactionModalAccountFields() {
      return [
        {
          label: this.$t('accountNumber'),
          key: 'bank.numbers',
        },
        {
          label: this.$t('iban'),
          key: 'bank.iban',
        },
      ]
    },
  },
  watch: {
    currentpageTransactions(val) {
      if (val) {
        this.withdrawList()
      }
    },
    '$store.state.Messaging.event': {
      async handler(newVal) {
        if (newVal === 'platform_forex_withdrawal_wire') {
          await this.withdrawList()
          this.$store.dispatch('Messaging/setEvent', '')
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.show = true
    await this.getCurrency()
    await this.getData()
    await this.withdrawList()
    await this.bankList()
    this.show = false
  },
  methods: {
    async showModal() {
      const valid = await this.$refs.simpleRules.validate()
      if (valid) {
        this.$bvModal.show('modal-scoped')
      }
    },
    async getData() {
      this.show = true
      await Promise.all([
        metatrader.getPlatforms().then(res => {
          this.platform = res.data.results
        }),
        await metatrader.getAccounts().then(res => {
          this.accounts = res.data.results
          // eslint-disable-next-line prefer-destructuring
          this.accSelected = res.data.results[0]
          // eslint-disable-next-line prefer-destructuring
          if (res.data.results[0]) this.accountDetail = res.data.results[0]
          this.platformSelected = this.platform.find(plat => plat.value === this.accountDetail.platform)

          if (this.$route.params.id) {
            const accountDetail = res.data.results.find(item => item.account === parseInt(this.$route.params.id, 10))
            this.accSelected = accountDetail
            this.accountDetail = accountDetail
            this.platformSelected = this.platform.find(plat => plat.value === accountDetail.platform)
          }
          this.show = false
        }),
      ])
        .catch(err => {
          console.log(err)
        })
    },
    getConvertRate(from, to) {
      fiat.getCurrency({
        to_currency: to,
        from_currency: from,
      }).then(res => {
        this.buyConvertRate = res.data.results.prices[0]?.buy
        this.sellConvertRate = res.data.results.prices[0]?.sell
      })
    },
    async bankList() {
      this.show = true
      await bankList.getDataInfo().then(res => {
        const numbers = res.data.results.filter(item => {
          // eslint-disable-next-line no-param-reassign
          item.ibanCurrency = `${item.iban} ${item.currency ? `(${item.currency.currency})` : ''}`
          return item.check_status === 'success'
        })
        this.bankCard = numbers
      })
    },
    async getCurrency() {
      await metatrader.currencyList({ type: 'fiat' }).then(res => {
        const Fcurrencies = [...res.data.results.filter(curr => curr.is_withdrawal).map(curr => ({ ...curr, fullname: `${curr.name} (${curr.currency})` }))]
        this.currencies = Fcurrencies
        this.currency = Fcurrencies[0] ? Fcurrencies[0] : ''
      })
    },
    async submit() {
      const data = {
        account: this.accSelected.account,
        platform: this.platformSelected.value,
        amount: this.amount.replace(/,/g, ''),
        currency: this.currency.currency,
        bank_id: this.numbers.id,
        code: this.confirmCode,
      }
      await metatrader.saveWireWithdrawal(data).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
        this.withdrawal = false
        this.otpModal = false
        this.$bvModal.hide('modal-scoped')
        this.withdrawList()
      }).finally(() => {
      })
    },
    withdrawalSendOtp() {
      this.resendOtpLoading = true
      const data = {
        currency: this.currency.currency,
        amount: this.amount.replace(/,/g, ''),
      }
      metatrader.withdrawalSendOtp(data).then(res => {
        this.verifyCounter = res.data.results.expiration_time * 60
        clearTimeout(this.verfyCounterTimeout)
        this.countDownTimer()
        this.otpModal = true
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
      }).catch(error => {
        if (error.status === 428) {
          this.otpModal = true
          this.verifyCounter = error.data.results.time
          clearTimeout(this.verfyCounterTimeout)
          this.countDownTimer()
        }
      }).finally(() => {
        this.resendOtpLoading = false
      })
    },
    countDownTimer() {
      if (this.verifyCounter > 0) {
        this.verfyCounterTimeout = setTimeout(() => {
          this.verifyCounter -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    onchangeAccountDetail(item) {
      this.accountDetail = item
      this.platformSelected = this.platform.find(plat => plat.value === item.platform)
    },
    async withdrawList() {
      await metatrader.wireWithdrawList({
        page: this.currentpageTransactions,
        per_page: this.perPageTransactions,
      }).then(res => {
        this.items = res.data.results.data
        this.totalTransactions = res.data.results.paginate.total
        this.currentpageTransactions = res.data.results.paginate.current_page
        this.perPageTransactions = res.data.results.paginate.per_page
      })
    },
    getFormattedDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    numberFormat(value) {
      return this.helpers.numberWithCommas(value)
    },
    changeCurrency() {
      this.getConvertRate(this.currency.currency, this.numbers.currency.currency)
    },
    openInfoTransactionModal(val) {
      this.infoTransactionModal = true
      this.selectedTransaction = [{
        ...val,
        currency: val.currency.currency,
        amount: this.numberWithCommas(val.amount),
      }]
    },
  },
}
</script>
    <style scoped>
      .transaction-card {
  background-color: #efefef;
  padding: 15px;
  border-radius: 5px;
}

.card-bg {
  background-color: #172238;
  padding: 15px;
  border-radius: 5px;
}
    .card-border {
      border-right: 1px solid #e3e1e9;
    }

    .invoice-border {
      border: 1px solid #92ff92;
    }
    [dir=rtl] .right-selector::v-deep ul li {
      direction: rtl !important;
    }
    </style>
    <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
